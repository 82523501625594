/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Career from 'views/Career';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const CareerPage = () => {
  return (
    <WithLayout
      component={Career}
      layout={Main}
    />
  )
};

export default CareerPage;
